// Here you can add other styles
.c-sidebar {
    color: #fff;
    background: #11a1d9;
};

.c-sidebar .c-sidebar-minimizer {
    background-color: #fff;
    opacity: 100;
};

.btn-primary {
    color: #fff;
    background-color: #11a1d9;
    border-color: #11a1d9;
    opacity: 100;
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #11a1d9;
    border-color: #11a1d9;
    opacity: 100;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #11a1d92e;
}

body {
    margin: 0;
    overflow-x: hidden;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.95rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: #3c4b64;
    background-color: #ebedef;
}



.c-avatar {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50em;
    width: 154px;
    height: 36px;
    font-size: 14.4px;
}